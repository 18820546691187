<template>
  <div id="edit-ke">
    <v-form
      @submit.prevent="submit"
    >
      <p
        v-if="editData.section === 'products'"
        class="mt-5"
      >
        {{ $t('onboarding.investmentSurvey.views.products.knowledgeQuestion',
            { product: $t(`onboarding.investmentSurvey.products.${editData.type}`) }
        )}}
      </p>
      <p
        v-else
        class="mt-5"
      >
        {{ $t('onboarding.investmentSurvey.views.services.experienceQuestion') }}
      </p>
      <!-- KNOWLEDGE -->
      <v-btn-toggle
        v-if="editData.section === 'products'"
        :class="['mt-5', 'mb-10', $vuetify.theme.dark ? 'knowledge-dark' : 'knowledge-light']"
        v-model="editData.knowledge"
        rounded
        mandatory
      >
        <v-btn
          v-for="answer in knowledge"
          :key="answer.value"
          :value="answer.value"
          :class="`${editData.knowledge === answer
            ? 'active'
            : ''}`
          "
        >
          {{ answer.text }}
        </v-btn>
      </v-btn-toggle>

      <!-- EXPERIENCE -->
      <div>
        <p v-if="editData.section === 'products'">
          {{ $t('onboarding.investmentSurvey.views.products.experienceQuestion',
              { product: $t(`onboarding.investmentSurvey.products.${editData.type}`) }
          )}}
        </p>
        <v-slider
          v-model="sliderValue"
          :tick-labels="tickLabels(editData.type)"
          :max="sliderLength(editData.type)"
          ticks="always"
        ></v-slider>
      </div>
    </v-form>
    <CardNav
      :nextText="nextText"
      :prevText="prevText"
      @next="save"
      @previous="closeModal"
      class="mt-5"
      :vertical="$vuetify.breakpoint.smAndDown"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import clone from '@/utils/clone';
import { mapFrontendValueToDbEnum, mapDbEnumToFrontendValue } from '@/mappings/onboarding/investmentSurvey';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'EditInvestmentSurvey',
  components: {
    CardNav,
  },
  props: {
    editData: {
      type: Object,
      required: true,
    },
    settings: {
      type: Boolean,
      default: false,
    },
    resetData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      sliderValue: 0,
    };
  },
  computed: {
    ...mapGetters('onboarding', [
      'knowledge',
      'experiences',
      'transactionNumber',
      'transactionVolume',
    ]),
    productType() {
      if (this.editData.type === 'InvestmentFund' || this.editData.type === 'GovernmentBonds') {
        return 'basic';
      }
      if (this.editData.type === 'Bond' || this.editData.type === 'Share') {
        return 'expanded';
      }
      if (this.editData.type === 'CryptoCurrency' || this.editData.type === 'UnsecuredJuniorDebt') {
        return 'extensiveOrSpecial';
      }
      return null;
    },
    prevText() {
      if (this.settings) {
        return this.$t('settings.knowledgeAndExperience.cancel');
      }
      return this.$t('onboarding.popup.close');
    },
    nextText() {
      if (this.settings) {
        return this.$t('settings.knowledgeAndExperience.apply');
      }
      return this.$t('settings.knowledgeAndExperience.save');
    },
  },
  watch: {
    editData(newVal) {
      this.sliderValue = this.checkExperience(newVal.experience, newVal.type);
    },
    resetData(newVal) {
      if (newVal) {
        this.updateUser(this.resetData);
        this.data = clone(this.$store.state.user.data);
        this.$emit('clear');
      }
    },
  },
  created() {
    this.sliderValue = this.checkExperience(this.editData.experience, this.editData.type);
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'updateOnboarding',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    tickLabels(key) {
      if (key === 'avgYearlyTransactions') {
        return this.transactionNumber.map((e) => e.text);
      }
      if (key === 'avgTransactionValue') {
        return this.transactionVolume.map((e) => e.text);
      }
      return this.experiences.map((e) => e.text);
    },
    sliderLength(item) {
      if (item === 'avgTransactionValue' || item === 'avgYearlyTransactions') {
        return Object.keys(mapDbEnumToFrontendValue[item]).length - 1;
      }
      return Object.keys(mapDbEnumToFrontendValue.rangeOfExperience).length - 1;
    },
    checkExperience(data, item) {
      if (item === 'avgTransactionValue' || item === 'avgYearlyTransactions') {
        return mapDbEnumToFrontendValue[item][data];
      }
      return mapDbEnumToFrontendValue.rangeOfExperience[data];
    },
    closeModal() {
      this.$emit('close');
    },
    async save() {
      if (this.settings) {
        this.data.classification.investorClassification
          .investorClassification = this.$store.state.user.data.classification
            .investorClassification.investorClassification;
      }
      // if (this.resetData) {
      //   this.updateUser(this.resetData);
      //   this.data = clone(this.$store.state.user.data);
      // }
      const enumSliderValue = mapFrontendValueToDbEnum.rangeOfExperience[this.sliderValue];
      if (this.editData.section === 'products') {
        const newProduct = this.data.investmentSurvey
          .investmentExperience[this.productType].find((item) => item.type === this.editData.type);
        newProduct.knowledge = this.editData.knowledge;
        newProduct.experience = enumSliderValue;
      } else if (this.editData.type === 'avgTransactionValue' || this.editData.type === 'avgYearlyTransactions') {
        this.data.investmentSurvey[this.editData.type] = mapFrontendValueToDbEnum[this.editData.type][this.sliderValue];
      } else {
        this.data.investmentSurvey.serviceExperience[this.editData.type] = enumSliderValue;
      }
      if (this.settings) {
        this.updateUser(this.data);
        this.$emit('close');
        return;
      }
      this.setLoading(true);
      try {
        let investmentSurvey;
        if (this.editData.section === 'products') {
          investmentSurvey = {
            products: this.data.investmentSurvey.investmentExperience,
          };
        } else {
          investmentSurvey = {
            services: {
              serviceExperience: this.data.investmentSurvey.serviceExperience,
              avgYearlyTransactions: this.data.investmentSurvey.avgYearlyTransactions,
              avgTransactionValue: this.data.investmentSurvey.avgTransactionValue,
            },
          };
        }
        const input = {
          investor: {
            investmentSurvey,
          },
          mainStage: 'investmentSurvey',
          subStage: this.editData.section === 'products' ? 'products' : 'services',
        };
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        this.$emit('close');
      } catch (error) {
        this.$notify.error(error.message);
      }
      this.setLoading(false);
    },
  },
};
</script>

<style lang="scss">
#edit-ke {
  .knowledge-light {
    border: 2px solid #CEB390;
    text-transform: uppercase;
    display: flex;
    .v-btn {
      width: 50%;
      background: #FCFCFC !important;
      font-weight: 900;
    }
    .active {
      color: #000 !important;
      background: #E6C8A0 !important;
    }
  }
  .knowledge-dark {
    border: 2px solid #CEB390;
    text-transform: uppercase;
    display: flex;
    .v-btn {
      width: 50%;
      background: #192128 !important;
      font-weight: 900;
    }
    .active {
      color: #000 !important;
      background: #E6C8A0 !important;
    }
  }
  .v-slider__tick-label {
    font-size: 10px !important;
  }
}
</style>
